import {Image} from '@shopify/hydrogen';
import type {Article} from '@shopify/hydrogen/storefront-api-types';
import {SRC_SET_SIZES} from '~/lib/const';
import {getSrcSetSizes} from '~/lib/utils';
import {Button, Link} from '../index';

export function BlogArticleCard({
  blogHandle,
  article,
  loading = 'lazy',
}: {
  blogHandle: string;
  article: Article;
  loading?: HTMLImageElement['loading'];
}) {
  return (
    <li key={article.id}>
      <div className="flex flex-col justify-between items-stretch h-full">
        {article.image && (
          <Link to={`/blog/${blogHandle}/${article.handle}`}>
            <div className="card-image aspect-[3/2]">
              <Image
                alt={article.image.altText || article.title}
                className="object-cover w-full transition-transform duration-1000 hover:scale-105"
                data={article.image}
                loading={loading}
                widths={SRC_SET_SIZES}
                sizes={getSrcSetSizes([90, 90, 33, 33, 33, 33])}
                loaderOptions={{
                  crop: 'center',
                  width: 3,
                  height: 2,
                }}
              />
            </div>
          </Link>
        )}
        <div className="my-6">{article.tags.join(', ')}</div>
        <h2 className="my-4 font-medium">{article.title}</h2>
        <div
          className="flex-grow"
          dangerouslySetInnerHTML={{__html: article.excerptHtml}}
        ></div>
        <div className="mt-4">
          <Button
            as={Link}
            to={`/blog/${blogHandle}/${article.handle}`}
            variant="inline"
            className="uppercase"
          >
            Leggi di più
          </Button>
        </div>
      </div>
    </li>
  );
}
